import React from 'react';
import "./footer.css";

const Footer = () => {
    return (
        <div className="bg-black text-white">
            <div className="footerHeading text-center py-2 sm:pt-8 text-4xl">
                Sign Up
            </div>
            <div className="footerSubHeading text-center text-2xl ">
                to our Newsletter
            </div>
            {/* <div className='text-center py-3 sm:py-5'><input type='email' placeholder='Email' /></div> */}
            <form className="form-wrapper" action='https://public.herotofu.com/v1/2ceb7e70-66a2-11ee-af93-87e1ad631227' method="post" acceptCharset="UTF-8">
            <div className="flex justify-center items-center py-3 sm:pt-10">
                <div className="input-container">
                    <input
                        required="required"
                        id="input"
                        type="email"
                        placeholder="Your Email"
                        name="Email"
                    />
                    <div className="underline"></div>
                    <div className="sideline"></div>
                    <div className="upperline"></div>
                    <div className="line"></div>
                </div>
            </div>
            <div className="text-center py-3 sm:py-5">
                <button className="footerSubmitButton">SUBMIT</button>
            </div>
            </form>
            <hr />
            <div className="text-center py-2">ⓒ TanzanitePlus 2023</div>
        </div>
    );
}

export default Footer;