import React, { useState } from 'react'
import "./contact.css"

const Contact = () => {
    return (
        <div className="form-main" id='contactus'>
      <div className="main-wrapper">
        <h2 className="contactHeading text-3xl pb-6 font-semibold">CONTACT FORM</h2>
                {/* form redirection use from the below link
                https://app.herotofu.com/  
                current login from shubham@gem-experience.com */} 
                <form action="https://public.herotofu.com/v1/222b6d00-6667-11ee-9198-bfee6681f065" method="post" acceptCharset="UTF-8" className="w-full max-w-5xl">
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-name">
                                Name
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="Name" id="grid-name" type="text" placeholder="Your Name" autoComplete="off" />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
                                E-mail
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="Email" id="grid-email" type="email" placeholder="Your e-mail" autoComplete="off"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-message">
                                Message
                            </label>
                            <textarea className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="Message" id="grid-message" type="email" rows="3" placeholder="Message for us" />
                        </div>
                    </div>
                    <div className="w-full md:w-4/5 px-3 mb-6 md:mb-0">
                        <input type="submit" value="Submit" className='hover:cursor-pointer bg-blue-900 text-white py-2 px-5 rounded-md hover:bg-blue-800 hover:text-gray-200' />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contact