import React from 'react'
import "./sidebar2.css"
import sidebarlogo from "../../../Assets/152X152.png"

const Sidebar2 = () => {
    function close() {
        document.getElementById("sideNavbarInput").checked = false;
    }
    return (
        <nav role="navigation" className='sideNavbar' id='sideNavbar'>
            <div id="menuToggle" className='w-fit'>
                <input type="checkbox" className='sideNavbarInput' id='sideNavbarInput' />
                <div className='logobackground w-44 h-16 fixed left-0 top-20' />
                {/* <div className='overlay w-screen h-screen fixed top-0' id='overlay' onClick={()=>close()}></div> */}
                {/* <div className='sideNavbarlogo w-fit'><img alt='' className='fixed' src={logo} width={60} /></div> */}
                <div className='w-fit'><span className='sidemenu fixed top-24 font-bold text-2xl'>MENU</span></div>
                <div id="menu" className='sideNavUl'>
                    <div className=''>
                        <div><img alt='' src={sidebarlogo} width={150} /></div>
                        <div className='py-16'>
                            <a className='sideNavbarActive' href="#landingpage"><li className='sideNavLi' onClick={()=>close()}>Home</li></a>
                            <a className='sideNavbarActive' href="#aboutus"><li className='sideNavLi' onClick={()=>close()}>About</li></a>
                            <a className='sideNavbarActive' href="#services"><li className='sideNavLi' onClick={()=>close()}>Services</li></a>
                            <a className='sideNavbarActive' href="#contactus"><li className='sideNavLi' onClick={()=>close()}>Contact</li></a>
                        </div>
                        <section className='h-20 w-full absolute left-0 bottom-0 pl-12 pt-4 border-t border-black'>ⓒ TanzanitePlus 2023</section>
                    </div>

                </div>
            </div>
        </nav>
    )
}

export default Sidebar2