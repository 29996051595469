import React from 'react'
import LandingPage from '../LandingPage/LandingPage'
import AboutPage from '../AboutPage/AboutPage'
import Services from '../Services/Services'
import Contact from '../Contact/Contact'
import "./mainpage.css"
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop'
import Footer from '../../Components/Footer/Footer'
import Sidebar2 from '../../Components/Sidebar/Sidebar2/Sidebar2'

const Mainpage = () => {
  return (
    <div className='mainpage' id='mainpage' dir="ltr">
      <Sidebar2 />
      <ScrollToTop />
      <LandingPage />
      <AboutPage />
      <Services />
      <Contact />
      <Footer />
      
    </div>
  )
}

export default Mainpage