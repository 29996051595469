import React from "react";
import "./error.css"

export default function ErrorPage() {
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1 className='errorpageh1'>Oops!</h1>
                    <h2 className='errorpageh2'>404 - The Page can't be found</h2>
                </div>
                <a href="/">Go TO Homepage</a>
            </div>
        </div>
    )
}