import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ErrorPage from './Container/ErrorPage/ErrorPage'
import Mainpage from './Container/Mainpage/mainpage'
import Footer from './Components/Footer/Footer'
import Sidebar2 from './Components/Sidebar/Sidebar2/Sidebar2'
import Test from './Container/Test/test'

const AppRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/sidebar2" element={<Sidebar2 />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoute