import React, { Suspense, lazy } from 'react'
import "./landingpage.css"
import "../Stylesheet/Stylesheet.css"
import landingLogo from "../../Assets/512X512.png";
import img1 from "../../Assets//landingDescription1.jpg"
import img2 from "../../Assets//landingDescription2.jpg"
import bgvideo from "../../Assets/landingVideo.mp4"

const LandingPage = () => {
  function open() {
    document.getElementById("landingSideInfo").style.width = "35vw";
    document.getElementById("landingoverlay").style.display = "block";
    document.body.style.overflow = "hidden";
  }

  function close() {
    document.getElementById("landingSideInfo").style.width = "0";
    document.getElementById("landingoverlay").style.display = "none";
    document.body.style.overflow = "auto";
  }
  return (
    <>
      <div className="LandingPage flex justify-center items-center min-h-screen " id="landingpage">
        <Suspense>
          <video autoPlay loop muted id="landingVideo">
            <source src={bgvideo} type="video/mp4" />
          </video>
        </Suspense>
        <div className="landingpageContainer my-10 sm:my-[20vh] w-[80vw] sm:w-[60vw] h-fit text-center flex  items-center flex-col">
          <img alt='' src={landingLogo} className="w-44 sm:w-64" />
          <h1 className="landindHeading text-3xl sm:text-4xl font-bold uppercase font-mono tracking-wider">
            A hue beyond compare
          </h1>
          <div className="landingPara text-sm sm:text-base px-10 py-5">
            A gem connoisseur with a global touch and renown. Over the years,
            we&#39;ve established ourselves as one of the premier and most
            trusted names in the colored stone industry.
          </div>
          {/* <button className='mt-14 mb-2 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none hover:text-red-500' onClick={() => setIsOpen(true)}>Know More ⓘ</button> */}
          <div>
            <button className="landingPageButton my-4" onClick={() => open()}>
              Know more
            </button>
          </div>
        </div>
      </div>
      {/* ======  right sidebar  ============= */}
      <div id="landingoverlay" className="h-screen w-screen fixed top-0" onClick={() => close()} ></div>
      <div className="flex justify-end">
        <section id="landingSideInfo" className="landingSideInfo fixed top-0 h-screen" >
          <LandingData Heading={"MINING & SOURCING - AFRICA"}
            Img={img1}
            SubHeading={"TANZANITE PLUS & COLOR CLARITY "}
            Description={"Tanzanite Plus & Color Clarity is a Tanzanian-based enterprise, renowned globally for its commitment to trust, honesty, and excellence in the colored stone industry. With roots deep inMining and sourcing exceptional colored stones, we have elevated our brand to become aleading player across the globe. Situated amidst the natural richness of Tanzania, Tanzanite Plus & Color Clarity boastsunparalleled access to a treasure trove of rare and exclusive color stones. Our extensive miningoperations employ state-of-the-art technology and responsible practices, ensuring that wedeliver only the finest gems to our discerning clientele."} WebLink={"www.gem-experience.com"} />
          <hr className="border-black border-x-2 my-3 w-[96%] ml-[2%]" />
          <LandingData Heading={"JEWELLERY - UAE | INDIA"}
            Img={img2}
            SubHeading={"GEM EXPERIENCE"}
            Description={"Under the esteemed umbrella of Tanzanite Plus & Color Clarity, Gem Experience emerges as a purveyor of high-quality, exclusive jewelry. Building upon the robust foundation of its parent compan s expertise in colored stones, Gem Experience elevates the realm of fine jewelry to new heights. With a strong operational presence in India and the United Arab Emirates, the brand extends its bespoke services to a global clientele."} WebLink={"www.gem-experience.com"} />
        </section>
      </div>
    </>
  );
}

const LandingData = ({ Heading, Img, SubHeading, Description, WebLink }) => {
  return (
    <div className=''>
      <div className='landingSidebarHeading sm:text-3xl sm:py-6 sm:pl-3 font-medium p-2'>{Heading}</div>
      <div className=''>
        <div className="sm:flex sm:items-start sm:text-justify sm:text-base text-xs text-justify">
          <p className='landingSidebarDescription sm:px-4 p-2 sm:text-sm'><img alt='' src={Img} className='sm:p-3 w-28 sm:w-36 float-left' /><span className='landingSidebarHeading text-blue-600 sm:text-lg text-sm text-start'>{SubHeading}</span><br />{Description}</p>
        </div>
      </div>
      <a href={`https://${WebLink}`} className='landingSidebarDescription text-red-400 sm:ml-3 text-xs'>{WebLink}</a>
    </div >
  )
}

export default LandingPage;