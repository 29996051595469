import React, { useState } from 'react'
import "./scrolltotop.css"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToTop = () => {
    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.addEventListener('scroll', checkScrollTop)
    return (
        <button className="scrollTop" onClick={scrollTop} style={{ height: 40, display: showScroll ? 'flex' : 'none' }}><span className='scrollButtonSpan hidden md:block'>Scroll Up</span><span className='scrollButtonSpan block md:hidden'><ArrowUpwardIcon /></span></button>
    )
}

export default ScrollToTop