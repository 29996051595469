import React, { useState } from "react";
import ReactModal from "react-modal";
import "./aboutpage.css";
import profile from "../../Assets/founderProfile.jpg";

const AboutPage = () => {
    function open() {
        document.getElementById("aboutSideInfo").style.width = "35vw";
        document.getElementById("aboutoverlay").style.display = "block";
        document.body.style.overflow = "hidden";
    }
    
    function close() {
        document.getElementById("aboutSideInfo").style.width = "0";
        document.getElementById("aboutoverlay").style.display = "none";
        document.body.style.overflow = "auto";
    }

    return (
        <>
            <div
                className="AboutPage flex justify-center items-center min-h-screen"
                id="aboutus"
            >
                <div className="Aboutpagebody w-[80vw] sm:w-[60vw]">
                    <div className="aboutHeading text-3xl font-bold py-6 pl-6 tracking-wide">
                        About us
                    </div>
                    <h2 className="aboutSubHeading uppercase font-bold py-4 pl-6 text-2xl sm:text-4xl text-gray-800 tracking-wider">
                        Strong and Beautiful, Just Like Diamonds. 
                    </h2>
                    <div className="aboutPara text-sm sm:text-lg px-6 tracking-tight text-justify text-gray-700">
                        Embarking on a journey deep into the heart of the Earth&#39;s
                        treasures, Tanzanite Plus has been a beacon of excellence in the
                        realm of colored gemstone mining and manufacturing for the past 25
                        years. Our legacy is not just carved in the timeless stones we craft
                        but is also reflected in the trust and admiration of our global
                        clientele.
                        <br />
                    </div>
                    <div className="text-center">
                        <button className="aboutPageButton my-4" onClick={() => open()}>
                            Know more
                        </button>
                    </div>
                </div>
            </div>
            <div
                id="aboutoverlay"
                className="h-screen w-screen fixed top-0"
                onClick={() => close()}
            ></div>
            <div className="flex justify-end">
                <section
                    id="aboutSideInfo"
                    className="aboutSideInfo fixed top-0 h-screen"
                >
                    <AboutData
                        Heading={"About US"}
                        Subheading={"mine to market"}
                        Description={"We began our journey in mining and crafting colored stones and have gradually broadened ourexpertise into the realm of jewelry manufacturing. Our journey in the gemstone industry hasbeen enriched by our sister concerns: Color Clarity, Tanzanite Experience, and Gem Experience.Each brand, while distinct in its essence, collectively resonates with our core philosophy ofdelivering authenticity, unparalleled craftsmanship, and an immersive gem experience.<br />With a quarter-century behind us, our passion for these earth-born marvels remainsundiminished. We continue to delve deeper, seeking out nature's best-kept secrets, all whileupholding our commitment to ethical mining practices and sustainable sourcing."}
                        Description2={"Our business model is vertically integrated, allowing for full traceability and control over the entire life cycle of each gemstone. From sourcing directly from mines to crafting the stones into their final polished state, we ensure a level of quality and authenticity that is unparalleled in the market."}
                    />
                </section>
            </div>
        </>
    );
};

const AboutData = ({ Heading, Description, Subheading, Description2 }) => {
    return (
        <div className="">
            <div className="aboutSidebarHeading sm:text-3xl sm:py-6 sm:pl-3 font-medium text-xl p-2">{Heading}</div>
            <div className="">
                <div className="sm:flex sm:items-start sm:text-justify">
                    <p className="aboutSidebarDescription sm:px-4 sm:text-sm text-xs p-2 text-justify">
                        {Description}
                    </p>
                </div>
            </div>
            <div className="aboutSidebarHeading sm:text-3xl sm:py-6 sm:pl-3 font-medium text-xl p-2">{Subheading}</div>
            <div className="">
                <div className="sm:flex sm:items-start sm:text-justify">
                    <p className="aboutSidebarDescription sm:px-4 sm:text-sm text-xs p-2 text-justify">
                        {Description2}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
